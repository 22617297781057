import * as React from "react"
import { Head } from "../components/Head";

export default () => (
  <div>
    <Head />
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </div>
)
